/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GeneralError } from '../models/GeneralError'
import type { InterestProfiles } from '../models/InterestProfiles'
import type { InterestsList } from '../models/InterestsList'
import type { Profile } from '../models/Profile'
import type { ProfilesPage } from '../models/ProfilesPage'
import type { ProfileWithId } from '../models/ProfileWithId'
import type { ReportContext } from '../models/ReportContext'
import type { ReportGeneratedCheck } from '../models/ReportGeneratedCheck'
import type { ReportInterestItem } from '../models/ReportInterestItem'
import type { ReportProfilesRequest } from '../models/ReportProfilesRequest'
import type { ReportSingleInterest } from '../models/ReportSingleInterest'
import type { ReportSummary } from '../models/ReportSummary'
import type { ReportSummaryStatus } from '../models/ReportSummaryStatus'
import type { SignInRequest } from '../models/SignInRequest'
import type { Source } from '../models/Source'
import type { Token } from '../models/Token'
import type { User } from '../models/User'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class DemoApiService {
  /**
   * @param requestBody Sign in request
   * @returns Token JWT token
   * @returns GeneralError response for error
   * @throws ApiError
   */
  public static postAuthSignin(
    requestBody?: SignInRequest
  ): CancelablePromise<Token | GeneralError> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/auth/signin',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `response for error`,
      },
    })
  }

  /**
   * @returns string OK
   * @returns GeneralError response for error
   * @throws ApiError
   */
  public static postAuthSignout(): CancelablePromise<string | GeneralError> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/auth/signout',
      errors: {
        401: `response for error`,
      },
    })
  }

  /**
   * @returns User user info
   * @returns GeneralError response for error
   * @throws ApiError
   */
  public static getAuthInfo(): CancelablePromise<User | GeneralError> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/auth/info',
      errors: {
        401: `response for error`,
      },
    })
  }

  /**
   * @returns InterestsList list of all available interests
   * @returns GeneralError response for error
   * @throws ApiError
   */
  public static getInterests(): CancelablePromise<InterestsList | GeneralError> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/interests',
      errors: {
        401: `response for error`,
      },
    })
  }

  /**
   * @returns InterestsList list of all available interests
   * @returns GeneralError response for error
   * @throws ApiError
   */
  public static getRedzoneInterests(): CancelablePromise<InterestsList | GeneralError> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/interests/redzone',
      errors: {
        401: `response for error`,
      },
    })
  }

  /**
   * @param reportId string ID of report
   * @returns InterestsList list of all available interests
   * @returns GeneralError response for error
   * @throws ApiError
   */
  public static getReportInterests(
    reportId: string
  ): CancelablePromise<InterestsList | GeneralError> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/interests/{reportId}',
      path: {
        reportId: reportId,
      },
      errors: {
        401: `response for error`,
      },
    })
  }

  /**
   * @returns any return id for new report
   * @returns GeneralError response for error
   * @throws ApiError
   */
  public static postReportNew(): CancelablePromise<
    | {
        reportId: string
      }
    | GeneralError
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/report/new',
      errors: {
        401: `response for error`,
        403: `response for error`,
      },
    })
  }

  /**
   * @param reportId string ID of report
   * @param requestBody
   * @returns string profile successfully added
   * @returns GeneralError response for error
   * @throws ApiError
   */
  public static postReportAdd(
    reportId: string,
    requestBody?: ProfileWithId
  ): CancelablePromise<string | GeneralError> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/report/{reportId}/add',
      path: {
        reportId: reportId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `response for error`,
        403: `response for error`,
        404: `profile not found`,
      },
    })
  }

  /**
   * @param reportId string ID of report
   * @param source source network of target account
   * @param username username of target account
   * @param name name of target account
   * @param age age of target account
   * @param state state of target account
   * @param limit items to get
   * @param offset items to skip
   * @param requestBody profiles for reports search
   * @returns any list of matched profiles from source network
   * @returns GeneralError response for error
   * @throws ApiError
   */
  public static postProfiles(
    reportId: string,
    source?: Source,
    username?: string,
    name?: string,
    age?: number,
    state?: string,
    limit: number = 8,
    offset?: number,
    requestBody?: ReportProfilesRequest
  ): CancelablePromise<
    | {
        items: Array<ProfilesPage>
      }
    | GeneralError
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/profiles/{reportId}',
      path: {
        reportId: reportId,
      },
      query: {
        source: source,
        username: username,
        name: name,
        age: age,
        state: state,
        limit: limit,
        offset: offset,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `either \`source\` & \`username\` or \`name\` + \`age\` + \`state\` (\`age\`/\`state\` optionally) is required`,
        401: `response for error`,
        403: `response for error`,
      },
    })
  }

  /**
   * @param reportId string ID of report
   * @param interestId id of interests
   * @param limit items to get
   * @returns any list of profiles on which interest was found and additional info
   * @returns GeneralError response for error
   * @throws ApiError
   */
  public static getReportInterest(
    reportId: string,
    interestId: number,
    limit: number = 8
  ): CancelablePromise<
    | {
        interest: ReportSingleInterest
        reportContext: ReportContext
        profiles: Array<InterestProfiles>
      }
    | GeneralError
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/report/{reportId}/{interestId}',
      path: {
        reportId: reportId,
        interestId: interestId,
      },
      query: {
        limit: limit,
      },
      errors: {
        401: `response for error`,
      },
    })
  }

  /**
   * @param reportId string ID of report
   * @param interestId id of interests
   * @param source source network of target account
   * @param offset items to skip
   * @param limit items to get
   * @returns InterestProfiles page of profiles list on which interest was found
   * @returns GeneralError response for error
   * @throws ApiError
   */
  public static getReportProfiles(
    reportId: string,
    interestId: number,
    source: Source,
    offset?: number,
    limit: number = 8
  ): CancelablePromise<InterestProfiles | GeneralError> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/report/{reportId}/profiles/{interestId}/{source}',
      path: {
        reportId: reportId,
        interestId: interestId,
        source: source,
      },
      query: {
        offset: offset,
        limit: limit,
      },
      errors: {
        401: `response for error`,
      },
    })
  }

  /**
   * @param reportId string ID of report
   * @param limit items to get
   * @returns any Array of interests with profiles based on preview
   * @returns GeneralError response for error
   * @throws ApiError
   */
  public static getReport(
    reportId: string,
    limit: number = 8
  ): CancelablePromise<
    | (InterestsList & {
        profiles: Array<Profile>
        summary?: ReportSummary
        userInterests: Array<ReportInterestItem>
      })
    | GeneralError
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/report/{reportId}',
      path: {
        reportId: reportId,
      },
      query: {
        limit: limit,
      },
      errors: {
        400: `error on uncompleted report request`,
        401: `response for error`,
      },
    })
  }

  /**
   * @param reportId string ID of report
   * @returns any GPT Generated summary of report
   * @returns GeneralError response for error
   * @throws ApiError
   */
  public static getReportSummary(reportId: string): CancelablePromise<
    | {
        status: ReportSummaryStatus
        result?: ReportSummary
      }
    | GeneralError
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/report/{reportId}/summary',
      path: {
        reportId: reportId,
      },
      errors: {
        400: `error on uncompleted report request`,
        401: `response for error`,
      },
    })
  }

  /**
   * @param reportId string ID of report
   * @returns ReportGeneratedCheck Check if report is generated
   * @returns GeneralError response for error
   * @throws ApiError
   */
  public static getReportCheck(
    reportId: string
  ): CancelablePromise<ReportGeneratedCheck | GeneralError> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/report/{reportId}/check',
      path: {
        reportId: reportId,
      },
      errors: {
        400: `error on uncompleted report request`,
        401: `response for error`,
      },
    })
  }

  /**
   * @param reportId string ID of report
   * @param interestId id of interests
   * @param requestBody report issue on profiles with interests
   * @returns string issue reported
   * @returns GeneralError response for error
   * @throws ApiError
   */
  public static postReportIssue(
    reportId: string,
    interestId: number,
    requestBody?: {
      profiles: Array<ProfileWithId>
    }
  ): CancelablePromise<string | GeneralError> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/report/{reportId}/issue/{interestId}',
      path: {
        reportId: reportId,
        interestId: interestId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `response for error`,
        403: `response for error`,
        404: `report or interest not found`,
      },
    })
  }
}
