import { ApiClient, ApiClientError, ApiPromise, IncompleteReportApiClientError } from '.'
import {
  AuthService,
  BadDataErrorData,
  CancelablePromise,
  DataReceivingService,
  GeneralError,
  IssuesService,
  ProfileWithId,
  ReportCreationService,
  Source,
} from './generated'
import { SearchParams } from 'src/entities'

const httpClient: ApiClient = {
  authSignIn(username: string, password: string) {
    return new CancelablePromise(async (resolve, reject, onCancel) => {
      const cancelableRequest = AuthService.postAuthSignin({
        username,
        password,
      })
      onCancel(() => cancelableRequest.cancel())
      const request = cancelableRequest.catch((reason) => reason.body as GeneralError)
      const result = await request

      if (result && 'code' in result) {
        reject(
          new ApiClientError(
            "can't sign in",
            `server error with code ${result.code}: ${result.message}`
          )
        )
      } else if (result) {
        resolve(result)
      } else {
        reject(new ApiClientError("can't sign in", 'no data received'))
      }
    })
  },
  getReport(reportId: string) {
    return new CancelablePromise(async (resolve, reject, onCancel) => {
      let cancelableRequest = DataReceivingService.getReport(reportId)
      onCancel(() => cancelableRequest.cancel())
      const request = cancelableRequest.catch(
        (reason) => reason.body as GeneralError | (GeneralError & { data: BadDataErrorData })
      )
      try {
        const result = await request
        if (result && 'code' in result) {
          if (result.code === 400 && 'data' in result) {
            result.data.emptySources = result.data.emptySources || []
            reject(
              new IncompleteReportApiClientError(
                "can't get Report from the server",
                result.data.emptySources,
                result.data.request
              )
            )
          } else {
            reject(
              new ApiClientError(
                `can't get Report from the server:\n${result.message}`,
                `server error with code ${result.code}: ${result.message}`
              )
            )
          }
        } else if (result) {
          resolve(result)
        } else {
          reject(new ApiClientError("can't get Report from the server", 'no data received'))
        }
      } catch (e) {
        console.error(e)
        if (e instanceof Error) {
          reject(new ApiClientError("can't get Report from the server", e.message))
        } else {
          reject(new ApiClientError("can't get Report from the server", 'server error'))
        }
      }
    })
  },

  getAllInterests() {
    return new CancelablePromise(async (resolve, reject, onCancel) => {
      const cancelableRequest = DataReceivingService.getInterests()
      onCancel(() => cancelableRequest.cancel())
      const request = cancelableRequest.catch((reason) => reason.body as GeneralError)
      const result = await request
      if (result && 'code' in result) {
        reject(
          new ApiClientError(
            "can't get Interests from the server",
            `server error with code ${result.code}: ${result.message}`
          )
        )
      } else if (result) {
        resolve(result)
      } else {
        reject(new ApiClientError("can't get Interests from the server", 'no data received'))
      }
    })
  },

  getRedzoneInterests() {
    return new CancelablePromise(async (resolve, reject, onCancel) => {
      const cancelableRequest = DataReceivingService.getRedzoneInterests()
      onCancel(() => cancelableRequest.cancel())
      const request = cancelableRequest.catch((reason) => reason.body as GeneralError)
      const result = await request
      if (result && 'code' in result) {
        reject(
          new ApiClientError(
            "can't get Interests from the server",
            `server error with code ${result.code}: ${result.message}`
          )
        )
      } else if (result) {
        resolve(result)
      } else {
        reject(new ApiClientError("can't get Interests from the server", 'no data received'))
      }
    })
  },

  getReportInterests(reportId: string) {
    return new CancelablePromise(async (resolve, reject, onCancel) => {
      const cancelableRequest = DataReceivingService.getReportInterests(reportId)
      onCancel(() => cancelableRequest.cancel())
      const request = cancelableRequest.catch((reason) => reason.body as GeneralError)
      const result = await request
      if (result && 'code' in result) {
        reject(
          new ApiClientError(
            "can't get Interests from the server",
            `server error with code ${result.code}: ${result.message}`
          )
        )
      } else if (result) {
        resolve(result)
      } else {
        reject(new ApiClientError("can't get Interests from the server", 'no data received'))
      }
    })
  },

  getReportSummary(reportId: string) {
    return new CancelablePromise(async (resolve, reject, onCancel) => {
      const cancelableRequest = DataReceivingService.getReportSummary(reportId)
      onCancel(() => cancelableRequest.cancel())
      const request = cancelableRequest.catch((reason) => reason.body as GeneralError)
      const result = await request
      if (result && 'code' in result) {
        reject(
          new ApiClientError(
            "can't get ReportSummary from the server",
            `server error with code ${result.code}: ${result.message}`
          )
        )
      } else if (result) {
        resolve(result)
      } else {
        reject(new ApiClientError("can't get ReportSummary from the server", 'no data received'))
      }
    })
  },

  getReportCheck(reportId: string) {
    return new CancelablePromise(async (resolve, reject, onCancel) => {
      const cancelableRequest = DataReceivingService.getReportCheck(reportId)
      onCancel(() => cancelableRequest.cancel())
      const request = cancelableRequest.catch((reason) => reason.body as GeneralError)
      const result = await request
      if (result && 'code' in result) {
        reject(
          new ApiClientError(
            "can't get ReportGeneratedCheck from the server",
            `server error with code ${result.code}: ${result.message}`
          )
        )
      } else if (result) {
        resolve(result)
      } else {
        reject(
          new ApiClientError("can't get ReportGeneratedCheck from the server", 'no data received')
        )
      }
    })
  },

  getInterest(reportId: string, interestId: number) {
    return new CancelablePromise(async (resolve, reject, onCancel) => {
      const cancelableRequest = DataReceivingService.getReportInterest(reportId, interestId)
      onCancel(() => cancelableRequest.cancel())
      const request = cancelableRequest.catch((reason) => reason.body as GeneralError)
      const result = await request
      if (result && 'code' in result) {
        reject(
          new ApiClientError(
            "can't get Interest from the server",
            `server error with code ${result.code}: ${result.message}`
          )
        )
      } else if (result) {
        resolve(result)
      } else {
        reject(new ApiClientError("can't get Interest from the server", 'no data received'))
      }
    })
  },

  getInterestProfilesPage(reportId: string, interestId: number, source: Source, offset: number) {
    return new CancelablePromise(async (resolve, reject, onCancel) => {
      const cancelableRequest = DataReceivingService.getReportProfiles(
        reportId,
        interestId,
        source,
        offset
      )
      onCancel(() => cancelableRequest.cancel())
      const request = cancelableRequest.catch((reason) => reason.body as GeneralError)
      const result = await request
      if (result && 'code' in result) {
        reject(
          new ApiClientError(
            "can't get Interest from the server",
            `server error with code ${result.code}: ${result.message}`
          )
        )
      } else if (result) {
        resolve(result)
      } else {
        reject(new ApiClientError("can't get Interest from the server", 'no data received'))
      }
    })
  },

  createReport() {
    return new CancelablePromise(async (resolve, reject, onCancel) => {
      const cancelableRequest = ReportCreationService.postReportNew()
      onCancel(() => cancelableRequest.cancel())
      const request = cancelableRequest.catch((reason) => reason.body as GeneralError)
      const result = await request
      if (result && 'code' in result) {
        reject(
          new ApiClientError(
            `server can't create new Report:\n${result.message}`,
            `server error with code ${result.code}: ${result.message}`
          )
        )
      } else if (result) {
        resolve(result)
      } else {
        reject(new ApiClientError("can't create new Report", 'no data received'))
      }
    })
  },

  addSourceToReport(reportId: string, source: Source, profileId?: number) {
    return new CancelablePromise(async (resolve, reject, onCancel) => {
      const cancelableRequest = ReportCreationService.postReportAdd(reportId, {
        source,
        id: profileId,
      })
      onCancel(() => cancelableRequest.cancel())
      const request = cancelableRequest.catch((reason) => reason.body as GeneralError)
      const result = await request
      if (typeof result === 'object' && 'code' in result) {
        reject(
          new ApiClientError(
            `server can't add Profile Source to Report:\n${result.message}`,
            `server error with code ${result.code}: ${result.message}`
          )
        )
      } else if (result) {
        resolve()
      } else {
        reject(new ApiClientError("can't add Profile Source to Report", 'no data received'))
      }
    })
  },

  getProfileVariantsForNewReport(
    reportId: string,
    params: SearchParams,
    loaded = '',
    offset = 0,
    limit = 16
  ) {
    return new CancelablePromise(async (resolve, reject, onCancel) => {
      const cancelableRequest = ReportCreationService.postProfiles(
        reportId,
        params.source,
        params.username,
        params.name,
        params.age,
        params.state,
        limit,
        offset,
        { loaded }
      )
      onCancel(() => cancelableRequest.cancel())
      const request = cancelableRequest.catch((reason) => reason.body as GeneralError)
      const result = await request
      if (result && 'code' in result) {
        reject(
          new ApiClientError(
            `can't get Profile variants from the server:\n${result.message}`,
            `server error with code ${result.code}: ${result.message}`
          )
        )
      } else if (result) {
        resolve(result)
      } else {
        reject(new ApiClientError("can't get Profile variants from the server", 'no data received'))
      }
    })
  },
  sendInterestIssueReport(
    reportId: string,
    interestId: string,
    profiles: ProfileWithId[]
  ): ApiPromise<string> {
    return new CancelablePromise(async (resolve, reject, onCancel) => {
      const interestIdNum = parseInt(interestId)
      if (!interestIdNum) {
        reject(new ApiClientError('bad interestId', 'cannot parse interest id'))
        return
      }
      const cancelableRequest = IssuesService.postReportIssue(reportId, interestIdNum, { profiles })
      onCancel(() => cancelableRequest.cancel())
      const request = cancelableRequest.catch((reason) => reason.body as GeneralError)
      const result = await request
      if (typeof result === 'object' && 'code' in result) {
        reject(
          new ApiClientError(
            "can't post issue to the server",
            `server error with code ${result.code}: ${result.message}`
          )
        )
      } else if (result) {
        resolve(result)
      } else {
        reject(new ApiClientError("can't send InterestIssueReport", 'no data received'))
      }
    })
  },
}

export default httpClient
