/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GeneralError } from '../models/GeneralError'
import type { ProfilesPage } from '../models/ProfilesPage'
import type { ProfileWithId } from '../models/ProfileWithId'
import type { ReportProfilesRequest } from '../models/ReportProfilesRequest'
import type { Source } from '../models/Source'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class ReportCreationService {
  /**
   * @returns any return id for new report
   * @returns GeneralError response for error
   * @throws ApiError
   */
  public static postReportNew(): CancelablePromise<
    | {
        reportId: string
      }
    | GeneralError
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/report/new',
      errors: {
        401: `response for error`,
        403: `response for error`,
      },
    })
  }

  /**
   * @param reportId string ID of report
   * @param requestBody
   * @returns string profile successfully added
   * @returns GeneralError response for error
   * @throws ApiError
   */
  public static postReportAdd(
    reportId: string,
    requestBody?: ProfileWithId
  ): CancelablePromise<string | GeneralError> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/report/{reportId}/add',
      path: {
        reportId: reportId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `response for error`,
        403: `response for error`,
        404: `profile not found`,
      },
    })
  }

  /**
   * @param reportId string ID of report
   * @param source source network of target account
   * @param username username of target account
   * @param name name of target account
   * @param age age of target account
   * @param state state of target account
   * @param limit items to get
   * @param offset items to skip
   * @param requestBody profiles for reports search
   * @returns any list of matched profiles from source network
   * @returns GeneralError response for error
   * @throws ApiError
   */
  public static postProfiles(
    reportId: string,
    source?: Source,
    username?: string,
    name?: string,
    age?: number,
    state?: string,
    limit: number = 8,
    offset?: number,
    requestBody?: ReportProfilesRequest
  ): CancelablePromise<
    | {
        items: Array<ProfilesPage>
      }
    | GeneralError
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/profiles/{reportId}',
      path: {
        reportId: reportId,
      },
      query: {
        source: source,
        username: username,
        name: name,
        age: age,
        state: state,
        limit: limit,
        offset: offset,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `either \`source\` & \`username\` or \`name\` + \`age\` + \`state\` (\`age\`/\`state\` optionally) is required`,
        401: `response for error`,
        403: `response for error`,
      },
    })
  }
}
