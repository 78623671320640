import httpClient from './http-client'
import {
  AuthService,
  BadDataErrorData,
  CancelError,
  DemoApiService,
  GeneralError,
  ProfileWithId,
  Source,
} from './generated'
import axios from 'axios'
import JSONbig from 'json-bigint'
import { getStoredAuth } from 'src/context/AuthContext'
import * as process from 'process'
import { SearchParams } from 'src/entities'

axios.defaults.timeout = 300000

axios.interceptors.request.use(
  (req) => {
    if (req.transitional) {
      req.transitional.silentJSONParsing = false
      req.transitional.forcedJSONParsing = false
    }
    if (typeof window === 'undefined') {
      req.url = `${
        !req.url?.startsWith('http') ? process.env.API_SERVER_URL || 'http://localhost:8080' : ''
      }${req.url}`
    } else {
      const token = getStoredAuth()?.token
      if (token) {
        req.headers.Authorization = `Bearer ${token}`
      }
    }

    req.timeout = 300000

    req.transformRequest = (data) => {
      try {
        return JSONbig.stringify(data)
      } catch (e) {
        console.error(e)
        return data
      }
    }
    req.transformResponse = (data) => {
      try {
        return JSONbig.parse(data)
      } catch (e) {
        throw { message: data }
      }
    }
    return req
  },
  (error) => {
    console.log('request error', error)
    return Promise.reject(error)
  }
)

export interface ApiPromise<TResult> extends Promise<TResult> {
  cancel: () => void
}

export type SignInApiResult = Exclude<
  Awaited<ReturnType<typeof AuthService.postAuthSignin>>,
  GeneralError
>

export type ReportApiResult = Exclude<
  Awaited<ReturnType<typeof DemoApiService.getReport>>,
  GeneralError
>

export type ReportSummaryApiResult = Exclude<
  Awaited<ReturnType<typeof DemoApiService.getReportSummary>>,
  GeneralError
>

export type ReportCheckApiResult = Exclude<
  Awaited<ReturnType<typeof DemoApiService.getReportCheck>>,
  GeneralError
>

export type InterestsApiResult = Exclude<
  Awaited<ReturnType<typeof DemoApiService.getInterests>>,
  GeneralError
>

export type ProfileVariantsApiResult = Exclude<
  Awaited<ReturnType<typeof DemoApiService.postProfiles>>,
  GeneralError
>

export type InterestApiResult = Exclude<
  Awaited<ReturnType<typeof DemoApiService.getReportInterest>>,
  GeneralError
>

export interface ApiClient {
  authSignIn(username: string, password: string): ApiPromise<SignInApiResult>
  getReport(reportId: string): ApiPromise<ReportApiResult>

  getAllInterests(): ApiPromise<InterestsApiResult>
  getRedzoneInterests(): ApiPromise<InterestsApiResult>
  getReportInterests(reportId: string): ApiPromise<InterestsApiResult>
  getReportSummary(reportId: string): ApiPromise<ReportSummaryApiResult>
  getReportCheck(reportId: string): ApiPromise<ReportCheckApiResult>

  getInterest(reportId: string, interestId: number): ApiPromise<InterestApiResult>

  getInterestProfilesPage(
    reportId: string,
    interestId: number,
    source: Source,
    offset: number
  ): ApiPromise<InterestApiResult['profiles'][number]>

  createReport(): ApiPromise<{ reportId: string }>

  addSourceToReport(reportId: string, source: Source, profileId?: number): ApiPromise<void>

  getProfileVariantsForNewReport(
    reportId: string,
    params: SearchParams,
    loaded?: string,
    offset?: number,
    limit?: number
  ): ApiPromise<ProfileVariantsApiResult>

  sendInterestIssueReport(
    reportId: string,
    interestId: string,
    profiles: ProfileWithId[]
  ): ApiPromise<string>
}

export class ApiClientError extends Error {
  cause: string

  constructor(message: string, cause: string) {
    super(message)
    this.name = 'ApiClientError'
    this.cause = cause
  }
}

export class IncompleteReportApiClientError extends ApiClientError {
  emptySources: Source[]
  enteredProfile?: BadDataErrorData['request']

  constructor(
    message: string,
    emptySources: Source[],
    enteredProfile: IncompleteReportApiClientError['enteredProfile']
  ) {
    super(message, '')
    this.name = 'IncompleteReportApiClientError'
    this.emptySources = emptySources
    this.enteredProfile = enteredProfile
  }
}

export { CancelError as ApiCancelError }

let apiClient: ApiClient = tryToLoadMockedApiClient() || httpClient

export default apiClient

function tryToLoadMockedApiClient(): ApiClient | undefined {
  try {
    return require('./mocked-client').default
  } catch (_) {
    return undefined
  }
}
